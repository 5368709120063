<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Terms Of Service" />
        <TermsOfServiceContent />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import TermsOfServiceContent from '../TermsOfService/TermsOfServiceContent'
import Footer from '../Layout/Footer'

export default {
    name: 'TermsOfServicePage',
    components: {
        Navbar,
        PageTitle,
        TermsOfServiceContent,
        Footer,
    }
}
</script>
