import { createWebHistory, createRouter } from "vue-router";

// All Pages
import HomePage from "../components/Pages/HomePage";
import ContactPage from "../components/Pages/ContactPage";
import ErrorPage from "../components/Pages/ErrorPage";
import PricingPage from "../components/Pages/PricingPage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import TermsOfServicePage from "../components/Pages/TermsOfServicePage";
import FaqPage from "../components/Pages/FaqPage";

const routes = [
  {
    path: "/",
    component: HomePage,
  },
  { path: "/contact", component: ContactPage },
  { path: "/:pathMatch(.*)*", component: ErrorPage },
  { path: "/pricing", component: PricingPage },
  { path: "/privacy-policy", component: PrivacyPolicyPage },
  { path: "/terms-of-service", component: TermsOfServicePage },
  { path: "/faq", component: FaqPage },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
