<template>
  <footer class="footer-area bg-color">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <router-link to="/" class="logo">
              <img src="../../assets/images/logo.svg" alt="Sequencely" />
            </router-link>
            <p>
              Built for engineers, by engineers, Sequencely provides seamless Temporal hosting so you can focus on creating exceptional applications.
            </p>
            <!-- Assuming social links would be the same as current ones or updated accordingly -->
          </div>
        </div>
        
        <div class="col-lg-2 col-md-6 col-sm-6">
          <div class="single-footer-widget pl-5">
            <h3>Navigate</h3>
            <ul class="footer-links-list">
              <li><router-link to="/">Home</router-link></li>
              <li><router-link to="/pricing">Pricing</router-link></li>
              <li><router-link to="/contact">Contact</router-link></li>
              <li><a href="https://dev.to/sequencely">Changelog</a></li>
              <li><a href="https://dev.to/sequencely">Blog</a></li>
            </ul>
          </div>
        </div>
        
        <div class="col-lg-2 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3>Mission</h3>
            <ul class="footer-links-list">
              <li><a href="https://dev.to/sequencely">Our mission</a></li>
              <li><a href="https://temporal.io/">About Temporal</a></li>
              <li><a href="https://docs.sequencely.io">Docs</a></li>
            </ul>
          </div>
        </div>
        
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3>About company</h3>
            <ul class="footer-contact-info">
              <li>
                <i class="bx bx-map"></i> Sequencely Technologies, 470 Ramona St, Palo Alto, CA 94301
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <div class="footer-bottom-area">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <p>
              <i class="bx bx-copyright"></i>{{ currentYear }}
              <strong>Sequencely</strong>. We make workflows go vroom.
            </p>
          </div>
          
          <!-- Legal links updated according to new routing or removed if not applicable -->
          <div class="col-lg-6 col-md-6">
            <ul>
              <li>
                <router-link to="/privacy-policy">Privacy Policy</router-link>
              </li>
              <li>
                <router-link to="/terms-of-service">Terms & Conditions</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
