<template>
    <div class="about-area pb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img">
                        <img src="../../assets/images/about/about5.png" alt="image">
                        <div class="shape"><img src="../../assets/images/about/about-shape1.png" alt="shape"></div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <div class="content">
                            <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Streamlined
                                Temporal Hosting</span>
                            <h2>We Host Your Workflows, You Focus on Your App</h2>
                            <p>We take care of the underlying complexity of Temporal hosting so you can concentrate on
                                developing your application.</p>
                            <ul class="about-list">
                                <li>
                                    <span>
                                        <div class="icon">
                                            <font-awesome-icon :icon="['fas', 'lock']" />
                                        </div>
                                        Secure
                                        <img src="../../assets/images/our-mission/our-mission-shape2.png" alt="image">
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <font-awesome-icon :icon="['fas', 'dollar-sign']" />
                                        </div>
                                        Cheap
                                        <img src="../../assets/images/our-mission/our-mission-shape2.png" alt="image">
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <font-awesome-icon :icon="['fas', 'gears']" />
                                        </div>
                                        Streamlined
                                        <img src="../../assets/images/our-mission/our-mission-shape2.png" alt="image">
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <font-awesome-icon :icon="['fas', 'user-group']" />
                                        </div>
                                        Familiar
                                        <img src="../../assets/images/our-mission/our-mission-shape2.png" alt="image">
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <font-awesome-icon :icon="['fas', 'headset']" />
                                        </div>
                                        Support
                                        <img src="../../assets/images/our-mission/our-mission-shape2.png" alt="image">
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <font-awesome-icon :icon="['fas', 'thumbs-up']" />
                                        </div>
                                        Stable
                                        <img src="../../assets/images/our-mission/our-mission-shape2.png" alt="image">
                                    </span>
                                </li>
                            </ul>
                            <p>Our platform is built with the highest security standards to ensure zero data leaks and
                                unauthorized access, offering a cost-effective, pay-for-what-you-use model.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Features'
}
</script>
