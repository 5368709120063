<template>
    <div class="faq-area ptb-100">
        <div class="container">
            <div class="faq-accordion-tab">
                <div class="tabs">
                    <ul class="tabs__header">
                        <li 
                            class="tabs__header-item" 
                            v-for="tab in tabs" 
                            v-on:click="selectTab (tab.id)" 
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <i :class="tab.icon"></i>
                            {{tab.title}}
                        </li>
                    </ul>
                </div>

                <div class="tabs__container">
                    <div 
                        class="tabs__list"
                        ref='tabsList'
                    >
                        <div 
                            class="tabs__list-tab"
                            v-for="tab in tabs"
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <div class="faq-accordion">
                                <accordion>
                                    <accordion-item
                                        v-for="accordion in tab.faqAccordion"
                                        :key="accordion.id"
                                    >
                                        <template v-slot:accordion-trigger>
                                            <button class="accordion-button">
                                                <i :class='accordion.icon'></i>
                                                {{accordion.title}}
                                            </button>
                                        </template>
                                        <template v-slot:accordion-content>
                                            <p>{{accordion.desc}}</p>
                                        </template>
                                    </accordion-item>
                                </accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'FaqContent',
    components: {
        Accordion,
        AccordionItem
    },
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            tabs: [
                {
                    id: 1,
                    icon: 'bx bxs-badge-dollar',
                    title: 'Billing & Payments',
                    faqAccordion: [
                        {
                            id: 1,
                            icon: 'bx bx-chevron-down',
                            title: 'Do I get charged per workflow execution or registration?',
                            desc: 'You get charged based on the number of workflows you have registered.',
                        },
                        {
                            id: 2,
                            icon: 'bx bx-chevron-down',
                            title: 'What payment methods do you offer?',
                            desc: 'We support payment through credit card. Invoicing is available for all paid plans.',
                        },
                        {
                            id: 3,
                            icon: 'bx bx-chevron-down',
                            title: 'Are the prices monthly?',
                            desc: 'Yes, all pricing plans are per month. At the end of the month, an invoice will be sent to your registration email and the amount will be charged on your credit card.',
                        },
                        {
                            id: 4,
                            icon: 'bx bx-chevron-down',
                            title: 'What is your refund policy?',
                            desc: 'For monthly plans, there is no refund and your access will stop at the end of your billing cycle. Every plan has 1 week of free trial.',
                        },
                        {
                            id: 5,
                            icon: 'bx bx-chevron-down',
                            title: 'What is a workflow?',
                            desc: 'When we talk about workflows, what we mean is workflow types, not workflow executions.',
                        },
                    ]
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    }
}
</script>