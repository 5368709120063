<template>
    <div class="terms-of-service-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="terms-of-service-content">
                        <h2>Terms of Service</h2>
                        <p><i>Last Modified: November 20, 2020</i></p>
                        <p>These Customer Terms and Conditions (this "Agreement"), effective as of the date on which you
                            click a button or check a box (or something similar) acknowledging your acceptance of this
                            Agreement (the "Effective Date"), is by and between Sequencely, Inc., a Delaware corporation
                            with offices located at Kromnis 6, Thessaloniki, Greece, ("Sequencely") and the
                            entity on whose behalf the individual accepting this Agreement accepts this Agreement
                            ("Customer"). The individual accepting this Agreement hereby represents and warrants that it
                            is duly authorized by the entity on whose behalf it accepts this Agreement to so accept this
                            Agreement. Sequencely and Customer may be referred to herein collectively as the "Parties"
                            or individually as a "Party." The Parties agree as follows:</p>
                        <h3>1. Definitions.</h3>
                        <p>"Aggregated Data" means data and information related to or derived from Customer Data or
                            Customer's use of the Services that is used by Sequencely in an aggregate and anonymized
                            manner, including to compile statistical and performance information related to the
                            provision of the Services.</p>
                        <p>"Authorized User" means Customer's employees, consultants, contractors, and agents who are
                            authorized by Customer to access and use the Services under the rights granted to Customer
                            pursuant to this Agreement; and for whom access to the Services has been purchased
                            hereunder.</p>
                        <p>"Customer Data" means information, data, and other content, in any form or medium, that is
                            submitted, posted, or otherwise transmitted by or on behalf of Customer or an Authorized
                            User through the Services; provided that, for purposes of clarity, Customer Data does not
                            include Aggregated Data.</p>
                        <p>"Documentation" means Sequencely's end user documentation relating to the Services available
                            at sequencely.com.</p>
                        <p>"Harmful Code" means any software, hardware, or other technology, device, or means, including
                            any virus, worm, malware, or other malicious computer code, the purpose or effect of which
                            is to permit unauthorized access to, or to destroy, disrupt, disable, distort, or otherwise
                            harm or impede in any manner any computer, software, firmware, hardware, system, or network;
                            or any application or function of any of the foregoing or the security, integrity,
                            confidentiality, or use of any data processed thereby.</p>
                        <p>"Order" means the purchase order, order form, or other ordering document entered into by the
                            Parties that incorporates this Agreement by reference; or if Customer registered for the
                            Services through Sequencely's online ordering process, the results of such online ordering
                            process.</p>
                        <p>"Personal Information" means any information that, individually or in combination, does or
                            can identify a specific individual or by or from which a specific individual may be
                            identified, contacted, or located, including without limitation all data considered
                            "personal data", "personally identifiable information", or something similar under
                            applicable laws, rules, or regulations relating to data privacy.</p>
                        <p>"Sequencely IP" means the Services, the Documentation, and any and all intellectual property
                            provided to Customer or any Authorized User in connection with the foregoing. For the
                            avoidance of doubt, Sequencely IP includes Aggregated Data and any information, data, or
                            other content derived from Sequencely's provision of the Services but does not include
                            Customer Data.</p>
                        <p>"Services" means Sequencely's proprietary hosted software platform, as made available by
                            Sequencely to Authorized Users from time to time.</p>
                        <p>"Subscription Period" means the time period identified on the Order during which Customer's
                            Authorized Users may access and use the Services.</p>
                        <p>"Third-Party Products" means any third-party products provided with, integrated with, or
                            incorporated into the Services.</p>
                        <p>"Usage Limitations" means the usage limitations set forth in this Agreement and the Order,
                            including without limitation any limitations on the number of Authorized Users (if any), and
                            the applicable product, pricing, and support tiers agreed-upon by the Parties.</p>
                        <h3>2. Access and Use.</h3>
                        <h4>2.1 Provision of Access.</h4>
                        <p>Subject to and conditioned on Customer's compliance with the terms and conditions of this
                            Agreement, including without limitation the Usage Limitations, Sequencely will make
                            available to Customer during the Subscription Period, on a non-exclusive, non-transferable
                            (except in compliance with Section 13.8), and non-sublicensable basis, access to and use of
                            the Services, solely for use by Authorized Users. Such use is limited to Customer's internal
                            business purposes and the features and functionalities specified in the Order. Sequencely
                            shall provide to Customer the necessary access credentials to allow Customer to access the
                            Services.</p>

                        <h4>2.2 Documentation License.</h4>
                        <p>Subject to and conditioned on Customer's compliance with the terms and conditions of this
                            Agreement, Sequencely hereby grants to Customer a non-exclusive, non-transferable (except in
                            compliance with Section 13.8), and non-sublicensable license to use the Documentation during
                            the Subscription Period solely for Customer's internal business purposes in connection with
                            its use of the Services.</p>

                        <h4>2.3 Use Restrictions.</h4>
                        <p>Customer shall not use the Services for any purposes beyond the scope of the access granted
                            in this Agreement. Customer shall not at any time, directly or indirectly, and shall not
                            permit any Authorized Users to: (i) copy, modify, or create derivative works of the
                            Sequencely IP, in whole or in part; (ii) rent, lease, lend, sell, license, sublicense,
                            assign, distribute, publish, transfer, or otherwise make available the Services or
                            Documentation to any third party; (iii) reverse engineer, disassemble, decompile, decode,
                            adapt, or otherwise attempt to derive or gain access to any software component of the
                            Services, in whole or in part; (iv) remove any proprietary notices from the Sequencely IP;
                            (v) use the Sequencely IP in any manner or for any purpose that infringes, misappropriates,
                            or otherwise violates any intellectual property right or other right of any person, or that
                            violates any applicable law.</p>

                        <h4>2.4 Reservation of Rights</h4>
                        <p>Sequencely reserves all rights not expressly granted to Customer in this Agreement. Except
                            for
                            the
                            limited rights and licenses expressly granted under this Agreement, nothing in this
                            Agreement
                            grants, by implication, waiver, estoppel, or otherwise, to Customer or any third party any
                            intellectual property rights or other right, title, or interest in or to the Sequencely IP.
                        </p>

                        <h4>2.5 Suspension</h4>
                        <p>Notwithstanding anything to the contrary in this Agreement, Sequencely may temporarily
                            suspend
                            Customer's and any Authorized User's access to any portion or all of the Services if: (i)
                            Sequencely reasonably determines that (A) there is a threat or attack on any of the
                            Sequencely IP;
                            (B) Customer's or any Authorized User's use of the Sequencely IP disrupts or poses a
                            security risk
                            to the Sequencely IP or to any other customer or vendor of Sequencely; (C) Customer, or any
                            Authorized User, is using the Sequencely IP for fraudulent or illegal activities; (D)
                            subject to
                            applicable law, Customer has ceased to continue its business in the ordinary course, made an
                            assignment for the benefit of creditors or similar disposition of its assets, or become the
                            subject of any bankruptcy, reorganization, liquidation, dissolution, or similar proceeding;
                            or
                            (E) Sequencely's provision of the Services to Customer or any Authorized User is prohibited
                            by
                            applicable law; (ii) any vendor of Sequencely has suspended or terminated Sequencely's
                            access to or
                            use of any Third-Party Products required to enable Customer to access the Services; or (iii)
                            in
                            accordance with Section 5.1 (any such suspension described in subclause (i), (ii), or (iii),
                            a
                            "Service Suspension"). Sequencely shall use commercially reasonable efforts to provide
                            written
                            notice of any Service Suspension to Customer and to provide updates regarding resumption of
                            access to the Services following any Service Suspension. Sequencely shall use commercially
                            reasonable efforts to resume providing access to the Services as soon as reasonably possible
                            after the event giving rise to the Service Suspension is cured. Sequencely will have no
                            liability
                            for any damage, liabilities, losses (including any loss of data or profits), or any other
                            consequences that Customer or any Authorized User may incur as a result of a Service
                            Suspension.</p>

                        <h4>2.6 Aggregated Data</h4>
                        <p>Notwithstanding anything to the contrary in this Agreement, Sequencely may monitor Customer's
                            use of the Services and collect and compile Aggregated Data. As between Sequencely and
                            Customer, all right, title, and interest in Aggregated Data, and all intellectual property
                            rights therein, belong to and are retained solely by Sequencely. Customer acknowledges that
                            Sequencely may compile Aggregated Data based on Customer Data input into the Services.
                            Customer agrees that Sequencely may (i) make Aggregated Data available to third parties
                            including its other customers in compliance with applicable law, and (ii) use Aggregated
                            Data to the extent and in the manner permitted under applicable law</p>

                        <h3>3. Customer responsibilities</h3>
                        <h4>3.1 General</h4>
                        <p>Customer is responsible and liable for all uses of the Services and Documentation resulting
                            from access provided by Customer, directly or indirectly, whether such access or use is
                            permitted by or in violation of this Agreement. Without limiting the generality of the
                            foregoing, Customer is responsible for all acts and omissions of Authorized Users, and any
                            act or omission by an Authorized User that would constitute a breach of this Agreement if
                            taken by Customer will be deemed a breach of this Agreement by Customer. Customer shall use
                            reasonable efforts to make all Authorized Users aware of this Agreement's provisions as
                            applicable to such Authorized User's use of the Services and shall cause Authorized Users to
                            comply with such provisions.</p>

                        <h4>3.2 Third-Party Products</h4>
                        <p>Sequencely may from time to time make Third-Party Products available to Customer or Sequencely
                            may allow for certain Third-Party Products to be integrated with the Services to allow for
                            the transmission of Customer Data from such Third-Party Products into the Services. For
                            purposes of this Agreement, such Third-Party Products are subject to their own terms and
                            conditions. If Customer does not agree to abide by the applicable terms for any such
                            Third-Party Products, then Customer should not install or use such Third-Party Products. By
                            authorizing Sequencely to transmit Customer Data from Third-Party Products into the Services,
                            Customer represents and warrants to Sequencely that it has all right, power, and authority to
                            provide such authorization.</p>

                        <h4>3.3 Customer Control and Responsibility.</h4>
                        <p>Customer has and will retain sole responsibility for: (i) all Customer Data, including its
                            content and use; (ii) all information, instructions, and materials provided by or on behalf
                            of Customer or any Authorized User in connection with the Services; (iii) Customer's
                            information technology infrastructure, including computers, software, databases, electronic
                            systems (including database management systems), and networks, whether operated directly by
                            Customer or through the use of third-party services ("Customer Systems"); (iv) the security
                            and use of Customer's and its Authorized Users' access credentials; and (v) all access to
                            and use of the Services directly or indirectly by or through the Customer Systems or its or
                            its Authorized Users' access credentials, with or without Customer's knowledge or consent,
                            including all results obtained from, and all conclusions, decisions, and actions based on,
                            such access or use.</p>

                        <h3>4. Support</h3>
                        <p>During the Subscription Period, Sequencely will use commercially reasonable efforts to provide
                            Customer with basic customer support via Sequencely's standard support channels during
                            Sequencely's normal business hours.</p>

                        <h3>5. Fees and Taxes</h3>
                        <h4>5.1 Fees</h4>
                        <p>Where paid for services are agreed between Sequencely and Customer per the Order, Customer
                            shall pay Sequencely the fees ("Fees") identified in the Order without offset or deduction at
                            the cadence identified in the Order (e.g., monthly or annually). Fees paid by Customer are
                            non-refundable. If Customer fails to make any payment when due, and Customer has not
                            notified Sequencely in writing within ten (10) days of the payment becoming due and payable
                            that the payment is subject to a good faith dispute, without limiting Sequencely's other
                            rights and remedies: (i) Sequencely may charge interest on the undisputed past due amount at
                            the rate of 1.5% per month, calculated daily and compounded monthly or, if lower, the
                            highest rate permitted under applicable law; (ii) Customer shall reimburse Sequencely for all
                            reasonable costs incurred by Sequencely in collecting any late payments or interest, including
                            attorneys' fees, court costs, and collection agency fees; and (iii) if such failure
                            continues for ten (10) days or more, Sequencely may suspend Customer's and its Authorized
                            Users' access to any portion or all of the Services until such amounts are paid in full.</p>
                        <h4>5.2 Taxes</h4>
                        <p>All Fees and other amounts payable by Customer under this Agreement are exclusive of taxes
                            and similar assessments. Customer is responsible for all sales, use, and excise taxes, and
                            any other similar taxes, duties, and charges of any kind imposed by any federal, state, or
                            local governmental or regulatory authority on any amounts payable by Customer hereunder,
                            other than any taxes imposed on Sequencely's income. To the extent that Sequencely is required
                            by law to pay any such taxes, duties, or other charges to any governmental or regulatory
                            authority, Sequencely may invoice Customer for such taxes, duties, or other charges and
                            Customer will pay such invoiced amounts in accordance with this Agreement.</p>

                        <h3>6. Confidential Information</h3>
                        <h4>6.1 Definition</h4>
                        <p>From time to time during the Subscription Period, either Party may disclose or make available
                            to the other Party information about its business affairs, products, confidential
                            intellectual property, trade secrets, third-party confidential information, and other
                            sensitive or proprietary information, whether orally or in written, electronic, or other
                            form or media that: (i) is marked, designated or otherwise identified as "confidential" or
                            something similar at the time of disclosure or within a reasonable period of time
                            thereafter; or (ii) would be considered confidential by a reasonable person given the nature
                            of the information or the circumstances of its disclosure (collectively, "Confidential
                            Information"). Except for Personal Information, Confidential Information does not include
                            information that, at the time of disclosure is: (a) in the public domain; (b) known to the
                            receiving Party at the time of disclosure; (c) rightfully obtained by the receiving Party on
                            a non-confidential basis from a third party; or (d) independently developed by the receiving
                            Party without use of, reference to, or reliance upon the disclosing Party's Confidential
                            Information.</p>

                        <h4>6.2 Duty</h4>
                        <p>The receiving Party shall not disclose the disclosing Party's Confidential Information to any
                            person or entity, except to the receiving Party's employees, contractors, and agents who
                            have a need to know the Confidential Information for the receiving Party to exercise its
                            rights or perform its obligations hereunder ("Representatives"). The receiving Party will be
                            responsible for all the acts and omissions of its Representatives as they relate to
                            Confidential Information hereunder. Notwithstanding the foregoing, each Party may disclose
                            Confidential Information to the limited extent required (i) in order to comply with the
                            order of a court or other governmental body, or as otherwise necessary to comply with
                            applicable law, provided that the Party making the disclosure pursuant to the order shall
                            first have given written notice to the other Party and made a reasonable effort to obtain a
                            protective order; or (ii) to establish a Party's rights under this Agreement, including to
                            make required court filings. Further, notwithstanding the foregoing, each Party may disclose
                            the terms and existence of this Agreement to its actual or potential investors, debtholders,
                            acquirers, or merger partners under customary confidentiality terms.</p>

                        <h4>6.3 Return of Materials; Effects of Termination/Expiration.</h4>
                        <p>On the expiration or termination of the Agreement, the receiving Party shall promptly return
                            to the disclosing Party all copies, whether in written, electronic, or other form or media,
                            of the disclosing Party's Confidential Information, or destroy all such copies and certify
                            in writing to the disclosing Party that such Confidential Information has been destroyed.
                            Each Party's obligations of non-use and non-disclosure with regard to Confidential
                            Information are effective as of the Effective Date and will expire three (3) years from the
                            date of termination or expiration of this Agreement; provided, however, with respect to any
                            Confidential Information that constitutes a trade secret (as determined under applicable
                            law), such obligations of non-disclosure will survive the termination or expiration of this
                            Agreement for as long as such Confidential Information remains subject to trade secret
                            protection under applicable law.</p>

                        <h3>7. Personal Information</h3>
                        <p>Customer will ensure that its Customer Data, and its use of such Customer Data, complies with
                            this Agreement and any applicable law. Customer is responsible for properly configuring and
                            using the Services and taking its own steps to maintain appropriate security, protection,
                            and backup of Customer Data. Customer may not store or process protected health information
                            (as defined in HIPAA) using the Services unless Customer signs a Business Associate
                            Agreement with Sequencely. Customer may not store any payment cardholder information using the
                            Services without Sequencely's prior written approval.</p>
                        <h3>8. Term and Termination</h3>
                        <h4>8.1 Sequencely IP</h4>
                        <p>Customer acknowledges that, as between Customer and Sequencely, Sequencely owns all right, title,
                            and interest, including all intellectual property rights, in and to the Sequencely IP and,
                            with respect to Third-Party Products, the applicable third-party providers own all right,
                            title, and interest, including all intellectual property rights, in and to the Third-Party
                            Products.</p>

                        <h4>8.2 Customer Data</h4>
                        <p>Sequencely acknowledges that, as between Sequencely and Customer, Customer owns all right, title,
                            and interest, including all intellectual property rights, in and to the Customer Data.
                            Customer hereby grants to Sequencely a non-exclusive, royalty-free, worldwide license to
                            reproduce, distribute, and otherwise use and display the Customer Data and perform all acts
                            with respect to the Customer Data as may be necessary for Sequencely to provide the Services
                            to Customer, and a non-exclusive, perpetual, irrevocable, royalty-free, worldwide license to
                            reproduce, distribute, modify, and otherwise use and display Customer Data incorporated
                            within the Aggregated Data. Customer may export the Customer Data at any time through the
                            features and functionalities made available via the Services.</p>

                        <h4>8.3 Feedback</h4>
                        <p>If Customer or any of its employees or contractors sends or transmits any communications or
                            materials to Sequencely by mail, email, telephone, or otherwise, suggesting or recommending
                            changes to the Sequencely IP, including without limitation, new features or functionality
                            relating thereto, or any comments, questions, suggestions, or the like ("Feedback"),
                            Sequencely is free to use such Feedback irrespective of any other obligation or limitation
                            between the Parties governing such Feedback so long as Sequencely does not identify Customer
                            as the source of the Feedback without Customer's prior approval.</p>

                        <h3>9. Warranty Disclaimer</h3>
                        <p>The Sequencely IP is provided "as is" and Sequencely hereby disclaims all warranties, whether
                            express, implied, statutory, or otherwise. Sequencely specifically disclaims all implied
                            warranties of merchantability, fitness for a particular purpose, title, and
                            non-infringement, and all warranties arising from course of dealing, usage, or trade
                            practice. Sequencely makes no warranty of any kind that the Sequencely IP, or any products or
                            results of the use thereof, will meet Customer’s or any other person’s requirements, operate
                            without interruption, achieve any intended result, be compatible or work with any software,
                            system or other services, or be secure, accurate, complete, free of harmful code, or error
                            free.</p>

                        <h3>10. Indemnification</h3>
                        <h4>10.1 Sequencely Indemnification</h4>
                        <p>Sequencely shall indemnify, defend, and hold harmless Customer from and against any and all
                            losses, damages, liabilities, costs (including reasonable attorneys' fees) ("Losses")
                            incurred by Customer resulting from any third-party claim, suit, action, or proceeding
                            ("Third-Party Claim") that the Services, or any use of the Services in accordance with this
                            Agreement, infringes or misappropriates such third party's US copyrights or trade secrets;
                            provided that Customer promptly notifies Sequencely in writing of the claim, cooperates with
                            Sequencely, and allows Sequencely sole authority to control the defense and settlement of such
                            claim.
                            If such a claim is made or appears possible, Customer agrees to permit Sequencely, at
                            Sequencely's sole discretion: to (i) modify or replace the Services, or component or part
                            thereof, to make it non-infringing; or (ii) obtain the right for Customer to continue use.
                            If Sequencely determines that neither alternative is reasonably commercially available,
                            Sequencely may terminate this Agreement, in its entirety or with respect to the affected
                            component or part, effective immediately on written notice to Customer.
                            This Section 10.1 will not apply to the extent that the alleged infringement arises from:
                            (i) use of the Services in combination with data, software, hardware, equipment, or
                            technology not provided by Sequencely or authorized by Sequencely in writing; (ii) modifications
                            to the Services not made by Sequencely; (iii) Customer Data; or (iv) Third-Party Products.</p>

                        <h4>10.2 Customer Indemnification</h4>
                        <p>Customer shall indemnify, hold harmless, and, at Sequencely's option, defend Sequencely from and
                            against any Losses resulting from any Third-Party Claim that the Customer Data, or any use
                            of the Customer Data in accordance with this Agreement, infringes or misappropriates such
                            third party's US intellectual property or other rights and any Third-Party Claims based on
                            Customer's or any Authorized User's (i) negligence or willful misconduct; (ii) use of the
                            Services in a manner not authorized by this Agreement; or (iii) use of the Services in
                            combination with data, software, hardware, equipment or technology not provided by Sequencely
                            or authorized by Sequencely in writing; in each case provided that Customer may not settle any
                            Third-Party Claim against Sequencely unless Sequencely consents to such settlement, and further
                            provided that Sequencely will have the right, at its option, to defend itself against any such
                            Third-Party Claim or to participate in the defense thereof by counsel of its own choice.</p>

                        <h4>10.3 Sole Remedy</h4>
                        <p>This section 10.3 sets forth Customer's sole remedies and Sequencely's sole liability and
                            obligation for any actual, threatened, or alleged claims that the services infringe,
                            misappropriate, or otherwise violate any intellectual property rights of any third party.
                        </p>

                        <h3>11. Limitations of Liability</h3>
                        <p>Except for: (i) a party’s breach of its confidentiality obligations; (ii) a party’s indemnity
                            obligations; or (iii) a party’s gross negligence, fraud, or willful misconduct ("Excluded
                            Liabilities"), (a) in no event will either party be liable under or in connection with this
                            agreement under any legal or equitable theory, including breach of contract, tort (including
                            negligence), strict liability, and otherwise, for any: (1) consequential, incidental,
                            indirect, exemplary, special, enhanced, or punitive damages; (2) increased costs, diminution
                            in value or lost business, production, revenues, or profits; (3) loss of goodwill or
                            reputation; (4) use, inability to use, loss, interruption, delay or recovery of any data, or
                            breach of data or system security; or (5) cost of replacement goods or services, in each
                            case regardless of whether such party was advised of the possibility of such losses or
                            damages or such losses or damages were otherwise foreseeable; and (b) in no event will
                            either party’s aggregate liability arising out of or related to this agreement under any
                            legal or equitable theory, including breach of contract, tort (including negligence), strict
                            liability, and otherwise exceed the total amounts paid and/or payable to Sequencely under this
                            agreement in the twelve (12) months immediately preceding the claim; provided that,
                            notwithstanding the foregoing, Sequencely's aggregate liability arising out of or relating to
                            any excluded liabilities will not exceed three times (3x) the total amounts paid and/or
                            payable to Sequencely by Customer under this agreement in the twelve (12) months immediately
                            preceding the claim.</p>

                        <h3>12. Subscription Period and Termination</h3>
                        <h4>12.1 Subscription Period</h4>
                        <p>The initial term of this Agreement begins on the Effective Date and, unless terminated
                            earlier pursuant to Section 12.2, will continue in effect for the period identified in the
                            Order (the "Initial Subscription Period"). This Agreement will automatically renew for
                            additional successive terms equal to the length of the Initial Subscription Period unless
                            earlier terminated pursuant to this Agreement's express provisions or either Party gives the
                            other Party written notice of non-renewal at least thirty (30) days prior to the expiration
                            of the then-current term (each a "Renewal Subscription Period" and together with the Initial
                            Subscription Period, the "Subscription Period").]</p>

                        <h4>12.2 Termination</h4>
                        <p>In addition to any other express termination right set forth in this Agreement:

                            Sequencely may terminate this Agreement, effective on written notice to Customer, if Customer:
                            (i) fails to pay any amount when due hereunder, and such failure continues more than ten
                            (10) calendar days after Sequencely's delivery of written notice thereof; or (ii) breaches any
                            of its obligations under Section 2.3 or Section 6;
                            either Party may terminate this Agreement, effective on written notice to the other Party,
                            if the other Party materially breaches this Agreement, and such breach: (i) is incapable of
                            cure; or (ii) being capable of cure, remains uncured thirty (30) calendar days after the
                            non-breaching Party provides the breaching Party with written notice of such breach; or
                            either Party may terminate this Agreement, effective immediately upon written notice to the
                            other Party, if the other Party: (i) becomes insolvent or is generally unable to pay, or
                            fails to pay, its debts as they become due; (ii) files or has filed against it, a petition
                            for voluntary or involuntary bankruptcy or otherwise becomes subject, voluntarily or
                            involuntarily, to any proceeding under any domestic or foreign bankruptcy or insolvency law;
                            (iii) makes or seeks to make a general assignment for the benefit of its creditors; or (iv)
                            applies for or has appointed a receiver, trustee, custodian, or similar agent appointed by
                            order of any court of competent jurisdiction to take charge of or sell any material portion
                            of its property or business.</p>

                        <h4>12.3 Effect of Expiration or Termination</h4>
                        <p>Upon expiration or earlier termination of this Agreement, Customer shall immediately
                            discontinue use of the Sequencely IP and, without limiting Customer's obligations under
                            Section 6, Customer shall delete, destroy, or return all copies of the Sequencely IP and
                            certify in writing to the Sequencely that the Sequencely IP has been deleted or destroyed. No
                            expiration or termination will affect Customer's obligation to pay all Fees that may have
                            become due before such expiration or termination or entitle Customer to any refund.</p>

                        <h4>12.4 Survival</h4>
                        <p>This Section 12.4 and Sections 1, 5, 6, 8, 9, 10, 11, and 13 survive any termination or
                            expiration of this Agreement. No other provisions of this Agreement survive the expiration
                            or earlier termination of this Agreement.</p>
                        <h3>13. Miscellaneous</h3>
                        <h4>13.1 Entire Agreement</h4>
                        <p>This Agreement, together with any other documents incorporated herein by reference,
                            constitutes the sole and entire agreement of the Parties with respect to the subject matter
                            of this Agreement and supersedes all prior and contemporaneous understandings, agreements,
                            and representations and warranties, both written and oral, with respect to such subject
                            matter. In the event of any inconsistency between the statements made in the body of this
                            Agreement, the related Exhibits, and any other documents incorporated herein by reference,
                            the following order of precedence governs: (i) first, this Agreement; and (ii) second, any
                            other documents incorporated herein by reference.</p>

                        <h4>13.2 Notices</h4>
                        <p>All notices, requests, consents, claims, demands, waivers, and other communications hereunder
                            (each, a "Notice") must be in writing and addressed to the Parties at the addresses set
                            forth on the first page of this Agreement or as identified on the Order Form (or to such
                            other address that may be designated by the Party giving Notice from time to time in
                            accordance with this Section). All Notices must be delivered by personal delivery,
                            nationally recognized signed for on delivery courier (with all fees pre-paid), or email
                            (with confirmation of transmission). All email Notices to Sequencely must be sent to
                            legal@supabase.io. Except as otherwise provided in this Agreement, a Notice is effective
                            only: (i) upon receipt by the receiving Party; and (ii) if the Party giving the Notice has
                            complied with the requirements of this Section.</p>

                        <h4>13.3 Force Majeure</h4>
                        <p>In no event shall either Party be liable to the other Party, or be deemed to have breached
                            this Agreement, for any failure or delay in performing its obligations under this Agreement
                            (except for any obligations to make payments), if and to the extent such failure or delay is
                            caused by any circumstances beyond such Party's reasonable control, including but not
                            limited to acts of God, flood, fire, earthquake, explosion, war, terrorism, invasion, riot
                            or other civil unrest, strikes, labor stoppages or slowdowns or other industrial
                            disturbances, or passage of law or any action taken by a governmental or public authority,
                            including imposing an embargo.</p>

                        <h4>13.4 Amendment and Modification</h4>
                        <p>Sequencely may change this Agreement (except for any Orders) from time to time at its
                            discretion. The date on which the Agreement was last modified will be updated at the top of
                            this Agreement. Sequencely will provide Customer with reasonable notice prior to any
                            amendments or modifications taking effect, either by emailing the email address associated
                            with Customer's account on the Services or by another method reasonably designed to provide
                            notice to Customer. If Customer accesses or uses the Services after the effective date of
                            the revised Agreement, such access and use will constitute Customer's acceptance of the
                            revised Agreement beginning at the next Renewal Subscription Period or, if Customer enters
                            into a new Order with Sequencely, as of the date of execution of such Order.</p>

                        <h4>13.5 Waiver</h4>
                        <p>No failure or delay by either Party in exercising any right or remedy available to it in
                            connection with this Agreement will constitute a waiver of such right or remedy. No waiver
                            under this Agreement will be effective unless made in writing and signed by an authorized
                            representative of the Party granting the waiver.</p>

                        <h4>13.6 Severability</h4>
                        <p>If any provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction,
                            such invalidity, illegality, or unenforceability will not affect any other term or provision
                            of this Agreement or invalidate or render unenforceable such term or provision in any other
                            jurisdiction. Upon such determination that any term or other provision is invalid, illegal,
                            or unenforceable, the Parties shall negotiate in good faith to modify this Agreement so as
                            to effect their original intent as closely as possible in a mutually acceptable manner in
                            order that the transactions contemplated hereby be consummated as originally contemplated to
                            the greatest extent possible.</p>

                        <h4>13.7 Governing Law; Submission to Jurisdiction</h4>
                        <p>This Agreement is governed by and construed in accordance with the internal laws of the State
                            of California without giving effect to any choice or conflict of law provision or rule that
                            would require or permit the application of the laws of any jurisdiction other than those of
                            the State of California. Any legal suit, action, or proceeding arising out of or related to
                            this Agreement or the licenses granted hereunder must be instituted in the federal courts of
                            the United States or the courts of the State of California in each case located in San
                            Francisco County, California and each Party irrevocably submits to the exclusive
                            jurisdiction of such courts in any such suit, action, or proceeding.</p>

                        <h4>13.8 Assignment</h4>
                        <p>Customer may not assign any of its rights or delegate any of its obligations hereunder, in
                            each case whether voluntarily, involuntarily, by operation of law or otherwise, without the
                            prior written consent of Sequencely. Any purported assignment or delegation in violation of
                            this Section will be null and void. No assignment or delegation will relieve the assigning
                            or delegating Party of any of its obligations hereunder. This Agreement is binding upon and
                            inures to the benefit of the Parties and their respective permitted successors and assigns.
                        </p>

                        <h4>13.9 Export Regulation</h4>
                        <p>The Services utilize software and technology that may be subject to US export control laws,
                            including the US Export Administration Act and its associated regulations. Customer shall
                            not, directly or indirectly, export, re-export, or release the Services or the underlying
                            software or technology to, or make the Services or the underlying software or technology
                            accessible from, any jurisdiction or country to which export, re-export, or release is
                            prohibited by law, rule, or regulation. Customer shall comply with all applicable federal
                            laws, regulations, and rules, and complete all required undertakings (including obtaining
                            any necessary export license or other governmental approval), prior to exporting,
                            re-exporting, releasing, or otherwise making the Services or the underlying software or
                            technology available outside the US.</p>

                        <h4>13.10 US Government Rights</h4>
                        <p>Each of the Documentation and the software components that constitute the Services is a
                            "commercial item" as that term is defined at 48 C.F.R. § 2.101, consisting of "commercial
                            computer software" and "commercial computer software documentation" as such terms are used
                            in 48 C.F.R. § 12.212. Accordingly, if Customer is an agency of the US Government or any
                            contractor therefor, Customer only receives those rights with respect to the Services and
                            Documentation as are granted to all other end users, in accordance with (a) 48 C.F.R. §
                            227.7201 through 48 C.F.R. § 227.7204, with respect to the Department of Defense and their
                            contractors, or (b) 48 C.F.R. § 12.212, with respect to all other US Government users and
                            their contractors.</p>


                        <h4>13.11 Equitable Relief</h4>
                        <p>Each Party acknowledges and agrees that a breach or threatened breach by such Party of any of
                            its obligations under Section 6 or, in the case of Customer, Section 2.3, would cause the
                            other Party irreparable harm for which monetary damages would not be an adequate remedy and
                            agrees that, in the event of such breach or threatened breach, the other Party will be
                            entitled to equitable relief, including a restraining order, an injunction, specific
                            performance and any other relief that may be available from any court, without any
                            requirement to post a bond or other security, or to prove actual damages or that monetary
                            damages are not an adequate remedy. Such remedies are not exclusive and are in addition to
                            all other remedies that may be available at law, in equity or otherwise.</p>

                        <h4>13.12 Publicity</h4>
                        <p>Sequencely may identify Customer as a user of the Services and may use Customer's name, logo,
                            and other trademarks in Sequencely's customer list, press releases, blog posts,
                            advertisements, and website (and all use thereof and goodwill arising therefrom shall inure
                            to the sole and exclusive benefit of Customer). Otherwise, neither Party may use the name,
                            logo, or other trademarks of the other Party for any purpose without the other Party's prior
                            written approval.</p>



                        <p>If you have any further questions about these Terms of Service or if you need further
                            assistance, please contact us at team@sequencely.com.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="widget-area">
                        <div class="widget widget_insight">
                            <ul>
                                <li><router-link to="/contact">Contact Us</router-link></li>
                                <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                                <li class="active"><router-link to="/terms-of-service">Terms of Service</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TermsOfServiceContent',
}
</script>

<style scoped></style>
