<template>
    <div class="how-its-work-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="how-its-work-content">
                        <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Getting Started</span>
                        <h2>Easy Steps to Integrate Temporal with Sequencely<span class="overlay"></span></h2>
                        <p>Starting with Temporal doesn’t have to be complex. Sequencely offers streamlined hosting to launch your workflows in just a few clicks.</p>
                        <div class="inner-box">
                            <div class="single-item">
                                <div class="count-box">
                                    1
                                </div>
                                <h3>Sign Up and Configure</h3>
                                <p>Create your Sequencely account, and configure your Temporal instance with ease, using our one-click setup.</p>
                            </div>
                            <div class="single-item">
                                <div class="count-box">
                                    2
                                </div>
                                <h3>Deploy Your Workflows</h3>
                                <p>Register the endpoint URL that we are providing in your workers.</p>
                            </div>
                            <div class="single-item">
                                <div class="count-box">
                                    3
                                </div>
                                <h3>Monitor and Scale</h3>
                                <p>Utilize our support and stable platform to monitor performance, and easily scale your operations as your app grows.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="how-its-work-image" data-tilt>
                        <img src="../../assets/images/how-its-work.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GettingStarted'
}
</script>
