<template>
    <div class="main-banner-area">
        <div class="container">
            <div class="banner-area-content">
                <h1>Your Temporal workflows. In seconds.</h1>
                <p>Swiftly create a secure Temporal instance, without the management hassle.</p>
                <a href="https://app.sequencely.io" class="default-btn"><i class="flaticon-web"></i>Start<span></span></a>
                <img src="../../assets/images/main-banner/banner-five-main-pic1.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MainBanner'
}
</script>