<template>
    <div id="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2365.3738967691506!2d-122.16053490215482!3d37.44335321303209!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSequencely%20Technologies%2C%20470%20Ramona%20St%2C%20Palo%20Alto%2C%20CA%2094301!5e0!3m2!1sen!2sgr!4v1711380846127!5m2!1sen!2sgr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</template>   

<script>

export default {
    name: 'ContactMap'
}
</script>