<template>
    <div class="upcoming-bootcamps-area ptb-100 bg-fafafb">
        <div class="container">
            <div class="upcoming-bootcamps-content">
                <h2>We are here to help you in your Temporal journey</h2>
                <a href="https://calendly.com/christos-1gnn/30min" class="default-btn"><i
                        class="flaticon-right"></i>Schedule a free call<span></span></a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CallSchedule'
}
</script>