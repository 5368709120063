<template>
  <div class="pricing-area pt-100 pb-70">
    <div class="container">
      <div class="section-title">
        <h2>Pricing that works for you, not against you.</h2>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-pricing-box">
            <div class="pricing-header">
              <h3>STARTER</h3>
              <p>$5.99</p>
            </div>
            <ul class="pricing-features">
              <li>up to 10 workflows</li>
              <li>up to 100 activities</li>
              <li>5GB of storage</li>
              <li>Shared database</li>
              <li>24h of retention</li>
              <li>HTTPS support</li>
            </ul>
            <a href="https://app.sequencely.io" class="pricing-btn get-started-btn">Get Started</a>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-pricing-box">
            <div class="pricing-header">
              <h3>STARTUP</h3>
              <p>$29.99</p>
            </div>
            <ul class="pricing-features">
              <li>up to 50 workflows</li>
              <li>up to 500 activities</li>
              <li>50GB of storage</li>
              <li>Private database</li>
              <li>Up to 15d retention</li>
              <li>HTTPS support</li>
            </ul>
            <a href="https://app.sequencely.io" class="pricing-btn get-started-btn">Get Started</a>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-pricing-box">
            <div class="pricing-header">
              <h3>PRO</h3>
              <p>$99.99</p>
            </div>
            <ul class="pricing-features">
              <li>up to 100 workflows</li>
              <li>up to 1,000 activities</li>
              <li>300GB of storage</li>
              <li>Private database</li>
              <li>Up to 30d retention</li>
              <li>HTTPS support</li>
            </ul>
            <a href="https://app.sequencely.io" class="pricing-btn get-started-btn">Get Started</a>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-pricing-box">
            <div class="pricing-header">
              <h3>ENTERPRISE</h3>
              <a href="#" class="pricing-btn">Get in touch</a>
            </div>
            <ul class="pricing-features">
              <li>> 500 workflows</li>
              <li>> 10,000 activities</li>
              <li>Unlimited storage</li>
              <li>Private database</li>
              <li>Unlimited retention</li>
              <li>HTTPS support</li>
            </ul>
            <a href="mailto:team@sequencely.io" class="pricing-btn contact-us-btn">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PricingContent'
}
</script>

<style lang="scss">
.single-pricing-box {
  text-align: center; // Centers text for all content within a pricing box
}

.pricing-header h3, .pricing-header p {
  margin: 0 auto; // Ensures header content is centered, adjust if necessary
}

.pricing-features {
  list-style-type: none; // Removes list bullets, assuming you don't want them
  padding: 0; // Removes default padding, adjust as needed
  margin: 0 auto; // Centers the list, adjust if necessary
  display: inline-block; // Changes display to respect text-align center, adjust as needed
}

.get-started-btn, .contact-us-btn {
  display: inline-block; // Ensures buttons are block-level but respect text-align
  width: auto; // Adjust as necessary, ensures button width is based on content
  margin: 0 auto; // Centers button if inline-block doesn't fully center
  background-color: #4e63d7;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3658a7;
    text-decoration: none;
    color: #ffffff;
  }

  &:active {
    transform: scale(0.98);
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
    font-size: 14px;
  }
}

.contact-us-btn {
  background-color: #8400a8;
}
</style>
