<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Contact Us" />
        <ContactInfo />
        <ContactContent />
        <ContactMap />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import ContactInfo from '../Contact/ContactInfo'
import ContactContent from '../Contact/ContactContent'
import ContactMap from '../Contact/ContactMap'
import Footer from '../Layout/Footer'

export default {
    name: 'ContactPage',
    components: {
        Navbar,
        PageTitle,
        ContactInfo,
        ContactContent,
        ContactMap,
        Footer,
    }
}
</script>
