<template>
    <div class="solutions-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Our Services</span>
                <h2>Why Sequencely Stands Out</h2>
                <p>Deploy your Temporal workflows with confidence. With Sequencely, you gain the benefits of one-click hosting, comprehensive security, and scalable pricing that fits your needs.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <font-awesome-icon :icon="['fass', 'shield-cat']" />
                        </div>
                        <h3><router-link to="/services-details-one">Secure Hosting</router-link></h3>
                        <p>Rest easy knowing your data is protected. We guarantee zero data leaks and no unauthorized access, so your workflows remain yours alone.</p>
                        <router-link to="/services-details-one" class="view-details-btn">Learn More</router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <font-awesome-icon :icon="['fas', 'server']" />
                        </div>
                        <h3><router-link to="/services-details-one">Cost-Effective Scaling</router-link></h3>
                        <p>Don't pay for idle resources. With our pay-as-you-go model, you control your expenses and scale your usage according to your growth.</p>
                        <router-link to="/services-details-one" class="view-details-btn">Learn More</router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <font-awesome-icon :icon="['fas', 'headset']" />
                        </div>
                        <h3><router-link to="/services-details-one">Reliable Support</router-link></h3>
                        <p>Our support team is just a few Discord messages away. We're here to help you deploy your Temporal instances smoothly and swiftly.</p>
                        <router-link to="/services-details-one" class="view-details-btn">Learn More</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Services',
}
</script>
