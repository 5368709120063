import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import BootstrapVue3 from 'bootstrap-vue-3';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import './assets/custom.scss';
import './assets/css/boxicons.min.css';

// Helper function to add only the icon exports from each pack
function addIconsFromPack(iconPack) {
  Object.keys(iconPack)
    .filter(key => key !== 'fas' && key !== 'prefix' && typeof iconPack[key] === 'object') // Filter out unwanted properties
    .forEach(icon => library.add(iconPack[icon]));
}

// Add icons to the library
addIconsFromPack(fas);
addIconsFromPack(far);
addIconsFromPack(fab);

const app = createApp(App);

// Register the FontAwesomeIcon component globally
app.component('font-awesome-icon', FontAwesomeIcon);

// Use the necessary plugins
app.use(router);
app.use(BootstrapVue3);

// Mount the app
app.mount('#app');
