<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="privacy-policy-content">
                        <p><i>This Privacy Policy was last updated on December 11, 2023.</i></p>
                        <p>Thank you for your interest in Sequencely, Inc., ("Sequencely," "we," "our," or "us").
                            Sequencely provides a suite of open source tools, stitched together to build a seamless
                            developer experience. This Privacy Notice explains how information about you, that directly
                            identifies you, or that makes you identifiable ("personal information") is collected, used,
                            and disclosed by Sequencely in connection with our website at sequencely.com (the "Site")
                            and our services offered in connection with the Site (collectively with the Site, the
                            "Service").</p>
                        <h3>1. What Data We Get</h3>
                        <blockquote class="blockquote">
                            <p>We collect certain data from you directly, like information you enter yourself, data
                                about your participation in courses, and data from third-party platforms you connect
                                with Sequencely. We also collect some data automatically, like information about your
                                device and what parts of our Services you interact with or spend time using.</p>
                        </blockquote>
                        <h3>2. Data You Provide to Us</h3>
                        <p>We may collect different data from or about you depending on how you use the Services. Below
                            are some examples to help you better understand the data we collect.</p>
                        <h3>3. How We Get Data About You</h3>
                        <p>We use tools like cookies, web beacons, analytics services, and advertising providers to
                            gather the data listed above. Some of these tools offer you the ability to opt out of data
                            collection.</p>
                        <h3>4. What We Use Your Data For</h3>
                        <ol>
                            <li>Responding to your questions and concerns;</li>
                            <li>Sending you administrative messages and information, including messages from instructors
                                and teaching assistants, notifications about changes to our Service, and updates to our
                                agreements;</li>
                            <li>Sending push notifications to your wireless device to provide updates and other relevant
                                messages (which you can manage from the “options” or “settings” page of the mobile app);
                            </li>
                        </ol>
                        <h3>5. Your Choices About the Use of Your Data</h3>
                        <p>You can choose not to provide certain data to us, but you may not be able to use certain
                            features of the Services.</p>
                        <ul>
                            <li>To stop receiving promotional communications from us, you can opt out by using the
                                unsubscribe mechanism in the promotional communication you receive or by changing the
                                email preferences in your account. Note that regardless of your email preference
                                settings, we will send you transactional and relationship messages regarding the
                                Services, including administrative confirmations, order confirmations, important updates
                                about the Services, and notices about our policies.</li>
                            <li>The browser or device you use may allow you to control cookies and other types of local
                                data storage. Your wireless device may also allow you to control whether location or
                                other data is collected and shared. You can manage Adobe’s LSOs through their Website
                                Storage Settings panel.</li>
                            <li>To get information and control cookies used for tailored advertising from participating
                                companies, see the consumer opt-out pages for the Network Advertising Initiative and
                                Digital Advertising Alliance, or if you’re located in the European Union, visit the Your
                                Online Choices site. To opt out of Google’s display advertising or customize Google
                                Display Network ads, visit the Google Ads Settings page. To opt out of Taboola’s
                                targeted ads, see the Opt-out Link in their Cookie Policy.</li>
                            <li>To update data you provide directly, log into your account and update your account at
                                any time.</li>
                        </ul>
                        <h3>6. Our Policy Concerning Children</h3>
                        <p>We recognize the privacy interests of children and encourage parents and guardians to take an
                            active role in their children’s online activities and interests. Children under 13 (or under
                            16 in the European Economic Area) should not use the Services. If we learn that we’ve
                            collected personal data from a child under those ages, we will take reasonable steps to
                            delete it.</p>
                        <!-- Continuation of the Privacy Policy -->
                        <p>Privacy disclosures for the European Economic Area, United Kingdom, and Switzerland are
                            crucial. If you're located in these regions, you have specific rights under applicable data
                            protection laws. These include the right to access, correct, delete, or transfer your
                            personal information, as well as the right to restrict its processing or object to its
                            processing under certain circumstances.</p>

                        <h3>Region-specific Disclosures</h3>
                        <p>Residents of California have the right to request information about the disclosure of their
                            personal information to third parties for direct marketing purposes. In Nevada, residents
                            can opt out of the sale of certain personal information. European residents have additional
                            rights under the GDPR, including rights to data access, correction, deletion, and
                            portability.</p>

                        <h3>Information We Collect and Our Use</h3>
                        <p>We collect personal information through your use of the Service, from third parties, and
                            automatically via our Service. This includes registration information, payment information,
                            communications with us, inquiries and feedback, and information submitted through our
                            Sequencely AI tool.</p>

                        <h3>How We Share Personal Information</h3>
                        <p>Your personal information may be shared with third-party service providers, business
                            partners, advisors, affiliates, and in connection with a business transaction. We may also
                            share information as required by law or to protect our interests.</p>

                        <h3>Control Over Your Information</h3>
                        <p>You have control over your personal information, including the ability to opt out of
                            promotional communications, modify account information, and manage cookies and other
                            tracking technologies.</p>

                        <h3>How We Use Cookies and Other Tracking Technology</h3>
                        <p>We use tracking technologies to collect information about your use of our Site, which helps
                            us improve your user experience. You have options to control or opt out of certain data
                            collections.</p>

                        <h3>Data Retention and Security</h3>
                        <p>We retain your personal information as long as necessary to fulfill the purposes outlined in
                            this Privacy Notice, comply with our legal obligations, resolve disputes, and enforce our
                            agreements. We are committed to securing your personal data.</p>

                        <h3>Links to Third-Party Websites and Services</h3>
                        <p>Our Site may include links to third-party websites, plug-ins, and applications. Clicking on
                            those links may allow third parties to collect or share data about you. We do not control
                            these third-party websites and are not responsible for their privacy statements.</p>

                        <h3>Children’s Privacy</h3>
                        <p>Our Service is not intended for children under 13 years of age, and we do not knowingly
                            collect personal information from children under 13. If we learn we have collected or
                            received personal information from a child under 13 without verification of parental
                            consent, we will delete that information.</p>

                        <h3>Changes to This Privacy Notice</h3>
                        <p>We may update our Privacy Notice from time to time. We will notify you of any changes by
                            posting the new Privacy Notice on this page and updating the “Last Modified” date at the top
                            of this Privacy Notice.</p>

                        <h3>Contact Us</h3>
                        <p>If you have any questions about this Privacy Notice, please contact us at
                            team@sequencely.com.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="widget-area">
                        <div class="widget widget_insight">
                            <ul>
                                <li><router-link to="/contact">Contact Us</router-link></li>
                                <li class="active"><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                                <li><router-link to="/terms-of-service">Terms of Service</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PrivacyPolicyContent'
}
</script>