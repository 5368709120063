<template>
    <div>
        <div :class="{ 'mb-4': isMobile }">
            <NavbarStyleTwo />
        </div>
        <MainBanner />
        <Services />
        <Features />
        <CallSchedule />
        <GettingStarted />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import MainBanner from '../HomePage/Banner'
import Services from '../Common/Services'
import Features from '../Common/Features'
import CallSchedule from '../Common/CallSchedule'
import GettingStarted from '../Common/GettingStarted'
import Footer from '../Layout/Footer'

export default {
    name: 'HomePage',
    components: {
        NavbarStyleTwo,
        MainBanner,
        Services,
        Features,
        CallSchedule,
        GettingStarted,
        Footer,
    },
    data() {
        return {
            isMobile: false,
        }
    },
    methods: {
        checkMobile() {
            this.isMobile = window.innerWidth <= 768;
        }
    },
    mounted() {
        this.checkMobile();
        window.addEventListener('resize', this.checkMobile);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkMobile);
    }
}
</script>
