<template>
    <div :class="['navbar-area', { 'is-sticky': isSticky }]">
        <div class="parix-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/images/logo.svg" alt="logo">
                    </router-link>

                    <div class="navbar-toggler" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                        v-bind:class="{ 'active': button_active_state }"
                        v-on:click="button_active_state = !button_active_state">
                        <span class="icon-bar top-bar"></span>
                        <span class="icon-bar middle-bar"></span>
                        <span class="icon-bar bottom-bar"></span>
                    </div>

                    <div class="collapse navbar-collapse" :class="{ show: active }">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <router-link to="/" class="nav-link">Overview</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/pricing" class="nav-link">Pricing</router-link>
                            </li>
                            <li class="nav-item">
                                <a href="https://dev.to/sequencely" class="nav-link">Blog</a>
                            </li>
                            <li class="nav-item">
                                <a href="https://dev.to/sequencely" class="nav-link">Changelog</a>
                            </li>
                            <li class="nav-item">
                                <router-link to="/faq" class="nav-link">FAQ</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/contact" class="nav-link">Contact</router-link>
                            </li>
                        </ul>
                    </div>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <form class="search-box">
                                <input type="text" class="input-search" placeholder="Search for anything">
                                <button type="submit"><i class="flaticon-loupe"></i></button>
                            </form>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NavbarStyleTwo',
    data() {
        return {
            isSticky: false,
            active: false,
            button_active_state: false,
        }
    },

    mounted() {
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if (scrollPos >= 100) {
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    }
}
</script>