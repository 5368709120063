<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Frequently Asked Questions" />
        <FaqContent />
        <GetStartedGraphic />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import FaqContent from '../Faq/FaqContent'
import GetStartedGraphic from '../Common/GetStartedGraphic'
import Footer from '../Layout/Footer'

export default {
    name: 'FaqPage',
    components: {
        Navbar,
        PageTitle,
        FaqContent,
        GetStartedGraphic,
        Footer,
    }
}
</script>
